#audio-player-panel {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
  position: fixed;
  bottom: 0em;
  left: 0;
  right: 0;
  background: rgba(6, 46, 95, 0.85);
  padding: 30px 20px;
  transform: translateY(7rem);
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

@media (max-width: 700px) {
  #audio-player-panel {
    transform: translateY(10rem);
  }
}

:host([initialized]) #audio-player-panel {
  transition: transform 0.5s;
}

:host([open]) #audio-player-panel {
  transform: translateY(0rem);
}

@supports (
  (-webkit-backdrop-filter: blur(15px)) or (backdrop-filter: blur(15px))
) {
  #audio-player-panel {
    background: rgba(6, 46, 95, 0.35);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
  }
}

p {
  margin: 0;
}

a {
  color: white;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--color-medium-blue);
}

a:hover {
  text-decoration-color: var(--color-secondary);
}

strong {
  color: var(--color-yellow);
}

#metadata {
  width: clamp(200px, 35vw, 50vw);
}

#playback-controls {
  flex-grow: 1;
  display: flex;
  gap: .8rem;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
}

#playback-controls button {
  position: relative;
  background: transparent;
  border: none;
  padding: 10px 5px 0;
  font-size: 36px;
  color: white;
  cursor: pointer;
}

#playback-controls button:focus {
  outline: none;
}

.spinner {
  top: 5px;
  left: 0;
  width: 46px;
  position: absolute;
}

sl-progress-bar {
  width: clamp(140px, 20vw, 300px);
  --track-color: rgba(0, 0, 0, 0.2);
  --indicator-color: linear-gradient(to top, var(--color-primary), var(--color-secondary));
}

sl-progress-bar::part(indicator) {
  transition-duration: 50ms, 50ms;
  background: var(--indicator-color);
}

sl-range { /* volume slider */
  width: 7ch;
  margin-left: 1rem;
  --track-color-active: rgba(0, 0, 0, 0.3);
  --track-color-inactive: rgba(0, 0, 0, 0.3);
  --sl-color-primary-600: var(--color-vibrant-yellow);
  --sl-color-primary-500: var(--color-yellow);
  --thumb-size: 16px;
}

button.close {
  position: absolute;
  top: 4px;
  right: 0px;
  padding: 5px;
  font-size: 2.5em;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
}
